<template>
  <div
    class="flex w-full flex-col items-center px-2 py-2 pr-2.5"
    :class="{
      'bg-orange-100': product.popular && product.available
    }"
  >
    <div
      class="col-span-2 flex space-x-2 leading-normal"
      :class="{ 'text-gray-500': !product.available && !inSubscription }"
    >
      <div
        class="flex flex-grow flex-col items-center overflow-hidden text-sm sm:text-base"
      >
        <div
          :key="`product-${product.sku}`"
          class="default-transition group rounded-xl hover:cursor-pointer hover:bg-gray-100"
          @click="showModal('', 'ProductInfo', product)"
        >
          <div class="relative flex w-full items-center justify-center">
            <image-getter
              classes="object-cover  h-full quick-transition orign-center"
              :url="getImageUrl(product.image.food, 'ar-1_1,w-400')"
              default="/images/product/awaitingimage.webp"
              :key="product.sku"
            />

            <div
              :key="`product-${product.sku}`"
              class="absolute -bottom-3 -right-4 h-24 w-24 overflow-hidden rounded-full p-4 md:h-28 md:w-28 xl:h-32 xl:w-32"
            >
              <image-getter
                classes="object-cover  h-full"
                :url="getImageUrl(product.image.packaging, 'ar-1_1,w-200')"
                default="/images/product/awaitingimage.webp"
                :key="product.sku"
              />
            </div>
          </div>
        </div>
        <div
          class="mb-0.5 line-clamp-2 flex h-10 items-end space-x-1 whitespace-normal text-center text-sm font-medium"
        >
          <span>{{ product.shortname || product.name }}</span>
          <span class="" v-if="product.isKitten">(Kitten)</span>
        </div>

        <span class="flex text-xs text-orange-400" v-if="product.popular"
          ><font-awesome-icon
            class="ml-2 mr-2 text-base text-orange-400"
            :icon="['far', 'stars']"
          />Popular</span
        >
        <div
          class="line-clamp-2 px-0 text-center text-sm text-xs text-gray-600 sm:px-2"
        >
          {{ product.foodcomplete ? 'Complete' : 'Complementary' }}
          &middot;
          {{ product.foodtype === 'dry' ? product.increments : product.size }}g
        </div>
        <!-- <span
          v-if="checkIfNew(product.sku)"
          class="rounded-full flex items-center  bg-pink-400 text-xxs text-white uppercase font-sans px-2 py-1 "
          >New</span
        > -->
      </div>
    </div>

    <div
      class="col-span-2 flex w-full flex-none items-center justify-end text-sm font-semibold"
    >
      <div class="mt-4 w-full flex-none flex-grow" v-if="isAdjust">
        <button
          class="h-10 w-full rounded bg-yellow-200 p-2 px-2 text-center font-semibold focus:outline-none"
          v-if="count == 0"
          @click="increaseCount()"
          :disabled="checkDisabled"
          :class="
            checkDisabled
              ? 'pointer-events-none opacity-50'
              : 'hover:cursor-pointer hover:border-yellow-300 hover:bg-yellow-200'
          "
        >
          + Add me
        </button>

        <div
          class="col-span-2 flex w-full flex-col items-center justify-end space-y-3"
          v-else
        >
          <div class="flex w-full items-center px-2">
            <button
              class="disable-zoom flex h-10 w-10 flex-none items-center justify-center rounded-full border-2 text-gray-600 hover:bg-gray-100 focus:outline-none"
              @click="decreaseCount()"
              :class="
                totalCount !== increments ? 'border-gray-300' : 'border-red-400'
              "
            >
              <font-awesome-icon
                v-if="totalCount !== increments"
                :icon="['far', 'minus']"
              />
              <font-awesome-icon
                v-else
                class="text-xl text-red-400"
                :icon="['far', 'trash-can-xmark']"
              />
            </button>
            <div
              class="flex flex-none flex-grow space-x-1 p-1 px-1 text-center font-semibold leading-none"
              :class="
                product.foodtype === 'dry'
                  ? 'flex-col items-center'
                  : 'items-center justify-center'
              "
            >
              <span>{{ formatTotal(totalCount, true) }}</span>
              <span
                class="text-xxs mt-1 text-gray-600"
                v-if="product.foodtype === 'dry'"
                >{{ formatTotal(totalCount, true) > 1 ? 'BAGS' : 'BAG' }}</span
              >
            </div>
            <button
              class="disable-zoom flex h-10 w-10 flex-none items-center justify-center rounded-full border-2 border-gray-300 text-gray-600 hover:bg-gray-100 focus:outline-none"
              @click="increaseCount()"
              :disabled="checkDisabled"
              :class="{ 'pointer-events-none opacity-25': checkDisabled }"
            >
              <font-awesome-icon :icon="['far', 'plus']" />
            </button>
          </div>
          <div class="flex w-full px-2">
            <button
              @click="resetCount()"
              class="disable-zoom quick-transition flex h-10 w-full items-center justify-center rounded-full border-2 font-normal font-semibold text-gray-500 hover:border-red-400 hover:text-red-400 focus:outline-none"
            >
              Remove all
            </button>
          </div>
        </div>
        <add-sample-btn
          class="mt-3"
          v-if="count === 0 && canSample"
          :product="product"
        />
        <div v-if="count === 0 && canSample" class="mt-8">
          <button
            @click="notInterested()"
            class="disable-zoom quick-transition flex h-8 w-full items-center justify-center space-x-2 text-xs font-normal text-gray-400 hover:border-red-400 hover:text-red-400 hover:underline focus:outline-none"
          >
            <font-awesome-icon class="text-base" :icon="['far', 'eye-slash']" />
            <div>Hide for now</div>
          </button>
        </div>
      </div>
      <div class="mb-2 w-full px-1" v-else>
        <button
          @click="showModal('', 'ProductInfo', product)"
          class="group mt-3 h-10 w-full rounded bg-gray-100 p-2 px-2 text-center font-semibold hover:cursor-pointer hover:border-gray-300 hover:bg-gray-200 focus:outline-none"
        >
          Find out more...
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { includes } from 'lodash/fp';
import { mapGetters } from 'vuex';
import utils from '@/utils';

import ImageGetter from '@/components/ImageGetter.vue';
import AddSampleBtn from '../samples/AddSampleBtn.vue';
import { mapActions } from 'vuex/dist/vuex.common.js';
import { EventBus } from '@/event-bus';

export default {
  name: 'ProductItem',
  components: {
    ImageGetter,
    AddSampleBtn
  },
  props: {
    product: Object,
    products: Array,
    currentCat: Number,
    catId: String,
    totals: Object,
    hideLabel: {
      type: Boolean,
      default: false
    },
    canSample: {
      type: Boolean,
      default: false
    },
    isAdjust: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      increments: this.product.increments,
      count: 0,
      startingCount: 0,
      firstCount: false
    };
  },
  computed: {
    totalCount() {
      return this.count * this.increments;
    },

    needToAdd() {
      if (this.totals) {
        const totalWet =
          this.totals['wet_pouch'] +
          this.totals['wet_tray'] +
          this.totals['wet_can'] +
          this.totals['wet_smooth'] +
          this.totals['wet_chunk'] +
          this.totals['wet_tasty'];
        return Math.ceil(totalWet / 15) * 15 - totalWet;
      }
      return 0;
    },
    getPrice() {
      return this.needToAdd === 10 || this.needToAdd - 5 === 0;
    },
    inSubscription() {
      return this.startingCount > 0 ? true : false;
    },
    ...mapGetters({
      unavailableProducts: 'getUnavailableProducts',
      subValidation: 'subValidation'
    }),
    checkDisabled() {
      if (
        (includes('wet', this.product.foodtype) &&
          this.subValidation.isWetMax) ||
        (includes('dry', this.product.foodtype) && this.subValidation.isDryMax)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    count: function (newValue, oldValue) {
      if (this.firstCount) {
        this.$emit('updateProducts', {
          sku: this.product.sku,
          quantity: newValue,
          getPrice: this.checkPrice(newValue, oldValue),
          group: this.product.foodtype
        });
      } else {
        this.firstCount = true;
        this.$emit('updateProducts', {
          sku: this.product.sku,
          quantity: newValue,
          getPrice: this.checkPrice(newValue, oldValue),
          group: this.product.foodtype
        });
      }
    },
    currentCat: function () {
      this.setCount();
    }
  },
  methods: {
    ...mapActions({
      hideRecommendation: 'hideRecommendation'
    }),
    checkIfNew(sku) {
      return utils.isNewProduct(sku);
    },
    checkPrice(newValue, oldValue) {
      if (this.product.foodtype === 'dry') {
        return true;
      } else {
        if (newValue > oldValue) {
          return this.needToAdd == 5;
        } else {
          return this.needToAdd == 10;
        }
      }
    },
    showModal: function (title, component, data) {
      this.$store.commit('show_modal', {
        title: title,
        component: component,
        data: data,
        outerClose: true
      });
    },
    increaseCount: function () {
      this.count++;
    },
    resetCount: function () {
      this.count = 0;
    },
    formatTotal: function (amount, bags) {
      if (this.product.foodtype === 'dry') {
        if (bags) {
          return amount / this.product.increments;
        } else {
          if (amount < 1000) {
            return amount + 'g';
          } else {
            return amount / 1000 + 'kg';
          }
        }
      } else {
        return amount;
      }
    },
    decreaseCount: function () {
      if (!this.count == 0) {
        this.count--;
      }
    },
    setCount: function () {
      this.startingCount = this.product.quantity || 0;
      this.count = this.product.quantity || 0;
    },
    notInterested() {
      this.hideRecommendation({
        data: { sku: this.product.sku },
        id: this.catId
      }).then(() => {
        EventBus.$emit('recommendation-change', {
          sku: this.product.sku
        });
      });
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  },
  mounted() {
    this.setCount();
  }
};
</script>

<style lang="scss" scoped>
.text-xxs {
  font-size: 0.6rem;
}

.max-w-32 {
  max-width: 8rem;
}
</style>
