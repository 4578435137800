var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isAdjust)?_c('div',[_c('div',{staticClass:"text-md mb-8 mt-0 w-full px-2 text-center font-semibold sm:my-4 sm:mb-8 sm:px-8 sm:text-xl"},[_vm._v(" "+_vm._s(_vm.isAdjust ? 'Update' : 'Choose')+" monthly mix for "),(_vm.cat)?_c('span',{staticClass:"border-b-2 border-teal-400 sm:border-b-4"},[_vm._v(_vm._s(_vm.cat.name))]):_vm._e()]),(!_vm.isAdd)?_c('div',{staticClass:"flex pb-4"},[_c('div',{staticClass:"flex flex-grow items-center justify-center space-x-2 rounded-lg p-2 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none",class:_vm.activeView === 'box'
            ? 'pointer-events-none bg-gray-200 font-semibold'
            : '',on:{"click":function($event){return _vm.setActiveView('box')}}},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'box-open']}}),_c('div',[_vm._v("My Box")])],1),_c('div',{staticClass:"flex flex-grow items-center justify-center space-x-2 rounded-lg p-2 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none",class:_vm.activeView === 'recommendations'
            ? 'pointer-events-none bg-gray-200 font-semibold'
            : '',on:{"click":function($event){return _vm.setActiveView('recommendations')}}},[_c('span',{staticClass:"rounded-full bg-pink-400 px-2 py-1.5 text-[11px] uppercase leading-none tracking-wide text-white"},[_vm._v("New")]),_c('font-awesome-icon',{attrs:{"icon":['fal', 'sparkles']}}),_c('div',[_vm._v("Recommendations")])],1)]):_vm._e(),(_vm.isKitten)?_c('div',{staticClass:"mb-4 flex flex-col items-center justify-center rounded-lg bg-yellow-100 p-4 text-center text-sm text-gray-800 sm:mx-2 sm:mb-0 sm:flex-row"},[_c('div',{staticClass:"flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0"},[_c('div',{},[_c('font-awesome-icon',{staticClass:"text-md flex-none text-gray-700",attrs:{"fixed-width":"","icon":['far', 'circle-info']}}),_vm._v(" As you are a kitten we are just showing food most suitable for you... Want to see everything? ")],1),_c('span',{staticClass:"font-medium text-gray-900 underline",on:{"click":function($event){return _vm.toggleEverything()}}},[_vm._v(_vm._s(_vm.showEverything ? 'Show me just kitten' : 'Show me everything'))])])]):_vm._e()]):_vm._e(),_c('transition',{attrs:{"enter-active-class":"miniFadeInUp","leave-active-class":"miniFadeOutDown","mode":"out-in"}},[(_vm.activeView === 'box')?_c('div',{key:"box-view",staticClass:"w-full flex-grow sm:mt-2"},[(_vm.products)?_c('div',{staticClass:"flex bg-white/80",class:[
          _vm.isAdjust ? 'pb-20 sm:pb-12' : 'space-y-3',
          _vm.isFull ? '0 flex-col sm:pb-0' : 'flex-row flex-wrap sm:space-y-0',
          _vm.isFull && _vm.isAdjust ? 'space-y-4 sm:space-y-0' : 'space-y-3'
        ]},_vm._l((_vm.groupedProducts),function(group){return _c('product-category',{key:group.label,attrs:{"cat":_vm.cat,"group":group,"id":`section_${group.label}`,"totals":_vm.totals,"isAdjust":_vm.isAdjust,"is-full":_vm.isFull,"is-add":_vm.isAdd},on:{"getSubscriptionCost":_vm.getSubscriptionCost}})}),1):_vm._e()]):_c('div',{key:"recommendations-view",staticClass:"w-full flex-grow"},[_c('recommendations-list',{attrs:{"recommendations":_vm.cat.recommendations,"cat":_vm.cat,"cat-index":_vm.catIndex,"totals":_vm.totals},on:{"updateProducts":_vm.updateProducts}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }