<template>
  <form
    @submit.prevent="sampleSuccess ? removeSampleItem() : addSampleItem()"
    @mouseleave="setShowRemove"
  >
    <loading-button :is-loading="isLoading" :is-enabled="true" is-full is-camo>
      <div
        class="group flex h-10 w-full items-stretch justify-center rounded-lg p-2 px-2 text-center font-semibold"
        :class="
          sampleSuccess && showRemove
            ? 'bg-transparent hover:bg-red-50'
            : 'bg-gray-100'
        "
      >
        <div
          class="flex h-full w-full items-center justify-center space-x-4"
          :class="
            sampleSuccess && showRemove ? 'flex group-hover:hidden' : 'flex'
          "
        >
          <font-awesome-icon
            class="text-xl text-teal-400"
            v-if="sampleSuccess"
            :icon="['far', 'check']"
          /><span>{{ sampleSuccess ? 'Sample added' : 'Get a Sample' }}</span>
        </div>
        <div
          :class="
            sampleSuccess && showRemove
              ? 'hidden items-center justify-center space-x-4 text-red-400 group-hover:flex'
              : 'hidden'
          "
        >
          <font-awesome-icon
            class="text-xl text-red-400"
            v-if="sampleSuccess && showRemove"
            :icon="['far', 'trash-can-xmark']"
          /><span>Remove sample</span>
        </div>
      </div>
    </loading-button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import { EventBus } from '@/event-bus';
import LoadingButton from '../LoadingButton.vue';

export default {
  components: { LoadingButton },
  name: 'AddSampleBtn',
  data() {
    return {
      activeSamples: [],
      isLoading: false,
      sampleSuccess: false,
      showRemove: false
    };
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      samples: 'currentSamplesGetter'
    })
  },
  methods: {
    ...mapActions({
      addSample: 'addSample',
      sendEvent: 'sendMpEvent'
    }),

    addSampleItem() {
      this.isLoading = true;
      if (this.samples.length < 3) {
        const activeSamples = this.activeSamples;
        this.activeSamples.push(this.product.sku);

        this.addSample(activeSamples).then((response) => {
          this.$store.commit('update_samples', response);
          this.isLoading = false;
          this.sampleSuccess = true;
          this.sendEvent({
            event: 'Added Recommended Product Sample',
            properties: {
              location: this.$route.name,
              sku: this.product.sku,
              type: 'EVT'
            }
          });
        });
      } else {
        this.$store.commit('show_modal', {
          title: 'Remove Sample',
          component: 'AddSampleModal',
          data: this.product
        });
      }
    },
    removeSampleItem() {
      const samples = [...this.activeSamples];
      const index = samples.findIndex((item) => item === this.product.sku);
      samples.splice(index, 1);
      this.addSample(samples).then((response) => {
        this.$store.commit('update_samples', response);
        this.sampleSuccess = false;
        this.showRemove = false;
      });
    },
    setShowRemove() {
      if (this.sampleSuccess) {
        this.showRemove = true;
      } else {
        this.showRemove = false;
      }
    }
  },
  watch: {
    samples: function (val) {
      this.activeSamples = [...val];
    }
  },
  mounted() {
    if (!this.samples) {
      this.$store.dispatch('getSamples').then((response) => {
        this.activeSamples = [...this.response];
      });
    } else {
      this.activeSamples = [...this.samples];
    }

    EventBus.$on('added-sample-success', (sku) => {
      if (sku === this.product.sku) {
        this.isLoading = false;
        this.sampleSuccess = true;
        this.sendEvent({
          event: 'Added Recommended Product Sample',
          properties: {
            location: this.$route.name,
            sku: sku,
            type: 'EVT'
          }
        });
      }
    });
  }
};
</script>

<style></style>
