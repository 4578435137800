<template>
  <div>
    <div v-if="isAdjust">
      <div
        class="text-md mb-8 mt-0 w-full px-2 text-center font-semibold sm:my-4 sm:mb-8 sm:px-8 sm:text-xl"
      >
        {{ isAdjust ? 'Update' : 'Choose' }} monthly mix for

        <span v-if="cat" class="border-b-2 border-teal-400 sm:border-b-4">{{
          cat.name
        }}</span>
      </div>
      <div class="flex pb-4" v-if="!isAdd">
        <div
          class="flex flex-grow items-center justify-center space-x-2 rounded-lg p-2 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none"
          :class="
            activeView === 'box'
              ? 'pointer-events-none bg-gray-200 font-semibold'
              : ''
          "
          @click="setActiveView('box')"
        >
          <font-awesome-icon :icon="['fal', 'box-open']" />
          <div>My Box</div>
        </div>
        <div
          class="flex flex-grow items-center justify-center space-x-2 rounded-lg p-2 py-3 hover:cursor-pointer hover:bg-gray-100 sm:inline-flex sm:flex-none"
          :class="
            activeView === 'recommendations'
              ? 'pointer-events-none bg-gray-200 font-semibold'
              : ''
          "
          @click="setActiveView('recommendations')"
        >
          <span
            class="rounded-full bg-pink-400 px-2 py-1.5 text-[11px] uppercase leading-none tracking-wide text-white"
            >New</span
          >
          <font-awesome-icon :icon="['fal', 'sparkles']" />

          <div>Recommendations</div>
        </div>
      </div>
      <div
        v-if="isKitten"
        class="mb-4 flex flex-col items-center justify-center rounded-lg bg-yellow-100 p-4 text-center text-sm text-gray-800 sm:mx-2 sm:mb-0 sm:flex-row"
      >
        <div
          class="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0"
        >
          <div class="">
            <font-awesome-icon
              class="text-md flex-none text-gray-700"
              fixed-width
              :icon="['far', 'circle-info']"
            />
            As you are a kitten we are just showing food most suitable for
            you... Want to see everything?
          </div>
          <span
            @click="toggleEverything()"
            class="font-medium text-gray-900 underline"
            >{{
              showEverything ? 'Show me just kitten' : 'Show me everything'
            }}</span
          >
        </div>
      </div>
    </div>
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div
        v-if="activeView === 'box'"
        key="box-view"
        class="w-full flex-grow sm:mt-2"
      >
        <div
          v-if="products"
          class="flex bg-white/80"
          :class="[
            isAdjust ? 'pb-20 sm:pb-12' : 'space-y-3',
            isFull ? '0 flex-col sm:pb-0' : 'flex-row flex-wrap sm:space-y-0',
            isFull && isAdjust ? 'space-y-4 sm:space-y-0' : 'space-y-3'
          ]"
        >
          <product-category
            v-for="group in groupedProducts"
            :key="group.label"
            :cat="cat"
            :group="group"
            :id="`section_${group.label}`"
            :totals="totals"
            :isAdjust="isAdjust"
            @getSubscriptionCost="getSubscriptionCost"
            :is-full="isFull"
            :is-add="isAdd"
          />
        </div>
      </div>
      <div v-else key="recommendations-view" class="w-full flex-grow">
        <recommendations-list
          :recommendations="cat.recommendations"
          :cat="cat"
          :cat-index="catIndex"
          :totals="totals"
          @updateProducts="updateProducts"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import utils from '@/utils';
import ProductCategory from '@/components/products/ProductCategory';
import { mapGetters } from 'vuex';
import checkMinimums from '@/utilities/minimums';
import RecommendationsList from '../recommendations/RecommendationsList.vue';
export default {
  name: 'ProductSelection',
  props: {
    cat: {
      type: Object
    },
    catIndex: {
      type: Number
    },

    isAdjust: {
      type: Boolean
    },
    totalCats: {
      type: Number
    },

    hideDetail: {
      type: Boolean,
      default: false
    },
    isFull: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProductCategory,
    RecommendationsList
  },
  data() {
    return {
      selectedTab: 'wet',
      totals: null,
      groupedProducts: [],
      showEverything: false,
      activeView: 'box',
      isKitten: false
    };
  },
  computed: {
    color() {
      let color = utils.getCatColor(this.catIndex);
      return color;
    },
    ...mapGetters({
      products: 'getProducts',
      productGroups: 'productGroupsGetter',
      subValidation: 'subValidation'
    }),

    formatDry() {
      const amount = this.totals?.dry;
      if (amount < 1000) {
        return amount + 'g';
      } else {
        return amount / 1000 + 'kg';
      }
    }
  },
  watch: {
    cat: {
      deep: true,
      immediate: true,
      handler(value) {
        if (this.isAdjust) {
          const totals = {
            wet_pouch: 0,
            wet_tray: 0,
            wet_can: 0,
            wet_smooth: 0,
            wet_tasty: 0,
            wet_chunk: 0,
            dry: 0
          };

          value.products.forEach((p) => {
            const product = this.products.find(
              (product) => product.sku === p.sku
            );
            const amount = p.quantity * product.increments;

            totals[product.foodtype] += amount;
          });
          this.totals = totals;
          this.$emit('totalsUpdated', this.totals);
          const wet =
            this.totals.wet_pouch +
            this.totals.wet_tray +
            this.totals.wet_tasty +
            this.totals.wet_chunk +
            this.totals.wet_smooth +
            this.totals.wet_can;
          const dry = this.totals.dry;

          checkMinimums(wet, dry, this.totalCats);
        } else {
          this.totals = {
            wet_pouch: 0,
            wet_tray: 0,
            wet_can: 0,
            wet_smooth: 0,
            wet_tasty: 0,
            wet_chunk: 0,
            dry: 0
          };
        }
      }
    },
    'cat.isKitten': {
      handler(val) {
        this.isKitten = val;
        this.orderProducts();
      }
    }
  },
  mounted() {
    this.isKitten = this.cat.type === 'kitten' || this.cat.isKitten;
    this.orderProducts();
  },
  methods: {
    getSubscriptionCost() {
      this.$emit('getSubscriptionCost');
    },
    toggleEverything() {
      this.showEverything = !this.showEverything;
      this.orderProducts();
    },
    setActiveView(view) {
      this.activeView = view;
      if (view === 'recommendations') {
        this.$store.dispatch('sendMpEvent', {
          event: 'Viewed Recommendations',
          properties: {
            location: this.$route.name,
            type: 'EVT'
          }
        });
      }
    },
    updateProducts(data) {
      let group = this.groupedProducts.find((g) => g.key === data.group);
      if (!group) {
        const newGroup = {
          label: data.group,
          isOpen: false,
          selected: { sku: data.sku },
          items: []
        };

        this.groupedProducts.push(newGroup);
        let order = [
          'wet_can',
          'wet_pouch',
          'wet_chunk',
          'wet_smooth',
          'wet_tray',
          'dry'
        ];
        if (this.isKitten) {
          order = [
            'wet_pouch',
            'wet_chunk',
            'wet_tray',
            'wet_can',
            'wet_smooth',
            'dry'
          ];
        }

        // order the grouped products based on the order array
        this.groupedProducts.sort((a, b) => {
          return order.indexOf(a.key) - order.indexOf(b.key);
        });
        group = this.groupedProducts.find((g) => g.key === data.group);
      }
      // find the item in the added array that matches the data.sku and sets its quantity to data.quantity
      const item = group.items.find((i) => i.sku === data.sku);
      // if the item is found then set its quantity to data.quantity else add the item to the added array
      if (item) {
        item.quantity = data.quantity;
      }
    },
    orderProducts() {
      this.groupedProducts = JSON.parse(JSON.stringify(this.productGroups));

      // for each of the grouped items arrays make the first item have an isSelected property of true and the rest false
      this.groupedProducts.forEach((group) => {
        group.items.forEach((item, index) => {
          if (index === 0) {
            item.isSelected = true;
          } else {
            item.isSelected = false;
          }
        });
        this.$set(group, 'selected', group.items[0]);
        this.$set(group, 'isOpen', false);
      });

      // if the cat is a kitten then show only kitten products or products that are in the cat Products and have a quantiry greater than 0 or are a foodtype of wet_can or wet_smooth that are in the each groupedProducts items, otherwise dont filter out any items unless they are kitten and dont have a quantiry > 0
      this.groupedProducts.forEach((group) => {
        group.items = group.items.filter((item) => {
          if (this.showEverything) {
            return true;
          } else {
            if (this.isKitten) {
              return (
                (this.cat.products.some((p) => p.sku === item.sku) &&
                  this.cat.products.find((p) => p.sku === item.sku).quantity >
                    0) ||
                (item.available && item.category === 'kitten') ||
                item.foodtype === 'wet_can' ||
                item.foodtype === 'wet_smooth'
              );
            } else {
              return (
                // return if the item is availiable and not kitten, but if the item is in the cats products show it regardless of if it is kitten or available
                (item.available && item.category !== 'kitten') ||
                this.cat.products.some((p) => p.sku === item.sku)
              );
            }
          }
        });
      });

      this.groupedProducts.forEach((group) => {
        // order the group items by foodcomplete and name
        group.items = group.items.sort((a, b) => {
          if (a.foodcomplete === b.foodcomplete) {
            return a.name.localeCompare(b.name);
          } else {
            return a.foodcomplete ? -1 : 1;
          }
        });

        group.items.forEach((item) => {
          const catProduct = this.cat.products.find((p) => p.sku === item.sku);
          item.quantity = catProduct ? catProduct.quantity : 0;
        });
      });

      this.groupedProducts = this.groupedProducts.filter(
        (group) => group.items.length > 0
      );
      let order = [
        'wet_can',
        'wet_pouch',
        'wet_chunk',
        'wet_smooth',
        'wet_tray',
        'dry'
      ];
      if (this.isKitten) {
        order = [
          'wet_pouch',
          'wet_chunk',
          'wet_tray',
          'wet_can',
          'wet_smooth',
          'dry'
        ];
      }

      // order the grouped products based on the order array
      this.groupedProducts.sort((a, b) => {
        return order.indexOf(a.label) - order.indexOf(b.label);
      });
    }
  }
};
</script>

<style></style>
