import store from '@/store';

let multicatsDryMin = 2400;
let baseDryMin = 800;
let dryMax = store.state.core.maxDry;
let wetMin = 30;
let wetMax = store.state.core.maxWet;
function formatDry(amount) {
  if (amount < 1000) {
    return amount + 'g';
  } else {
    return amount / 1000 + 'kg';
  }
}

function checkMinimums(wet, dry, cats) {
  const sub = {
    isValid: true,
    message: ''
  };

  if (wet % 15) {
    sub.isValid = false;
    sub.message = `Total pouches / trays must be in increments of 15.<hr class='my-2 border-gray-700'> Current total is ${wet}`;
  }

  if (cats > 1) {
    multicatsDryMin = 1200;
  }

  if (wet === 0) {
    if (dry < multicatsDryMin) {
      sub.isValid = false;
      sub.message = `Add at least <strong>${formatDry(
        multicatsDryMin
      )}</strong> of dry food to continue. <hr class='my-2 border-gray-700'>Current total is ${formatDry(
        dry
      )}`;
    }
  } else {
    if (dry < baseDryMin && dry != 0) {
      sub.isValid = false;
      sub.message = `Add at least <strong>${formatDry(
        baseDryMin
      )}</strong> of dry food to continue. <hr class='my-2 border-gray-700'>Current total is ${formatDry(
        dry
      )}`;
    }
    if (wet < wetMin) {
      sub.isValid = false;
      sub.message = `Add at least <strong>${wetMin}</strong> pouches / trays minimum to continue. <hr class='my-2 border-gray-700'>Current total is ${wet}`;
    }
  }

  if (wet === 0 && dry === 0) {
    sub.isValid = false;
    sub.message = 'Add some food to your box to continue ';
  }

  if (wet > wetMax) {
    sub.isValid = false;
    sub.message =
      'Maximum of <strong>' +
      wetMax +
      '</strong> pouches / trays per cat reached';
  }

  if (dry > dryMax) {
    sub.isValid = false;
    sub.message =
      'Maximum of <strong>' +
      formatDry(dryMax) +
      '</strong>  of dry food per cat reached';
  }

  if (wet >= wetMax) {
    sub.isWetMax = true;
  } else {
    sub.isWetMax = false;
  }
  if (dry >= dryMax) {
    sub.isDryMax = true;
  } else {
    sub.isDryMax = false;
  }

  store.commit('update_minimum_sub', sub);
}

export default checkMinimums;
