<template>
  <div>
    <div v-if="activeRecommendations.length > 0">
      <div class="py-8 pb-2 pt-6 text-center">
        Here's some recommendations we think
        {{ cat.name }} might like.
      </div>
      <div class="mb-4 text-center">
        Get a sample {{ subscription.areSamplesFree ? '' : 'for 75p each' }} or
        add them to your box!
      </div>

      <div class="mb-64 overflow-hidden rounded-lg border-2">
        <div class="-mr-[2px] -mt-[2px]">
          <div
            class="grid-items-wrapper relative grid w-full flex-none grid-cols-2 text-sm md:grid-cols-3 lg:grid-cols-4"
          >
            <div
              class="relative w-full"
              :class="index !== activeRecommendations.length ? 'grid-item' : ''"
              :key="`reccomendation-${val.sku}`"
              v-for="(val, index) in activeRecommendations"
            >
              <product-item
                :product="val"
                :key="val.sku"
                :totals="totals"
                @updateProducts="updateProducts"
                can-sample
                is-adjust
                :cat-id="cat.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex h-64 items-start justify-center">
      <div class="max-w-lg px-8 py-8 text-center">
        We've run out of recommendations for you! Pop back another time, who
        knows, we may just have something new to try!
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from '../products/ProductItem.vue';
import { mapActions, mapGetters } from 'vuex/dist/vuex.common.js';
import { EventBus } from '@/event-bus';
export default {
  components: { ProductItem },
  name: 'RecommendationsList',
  props: {
    recommendations: {
      type: Array,
      required: true
    },
    cat: {
      type: Object,
      required: true
    },
    totals: {
      type: Object,
      required: true
    },
    catIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeRecommendations: [],
      nextRecommendations: []
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      subscription: 'getCurrentSubscription'
    })
  },
  methods: {
    ...mapActions({
      sendEvent: 'sendMpEvent'
    }),
    updateProducts: function (payload) {
      let products = this.cat.products;
      const hasProduct = products.find((p) => p.sku === payload.sku);
      if (hasProduct) {
        hasProduct.quantity = payload.quantity;
      } else {
        const pl = { ...payload };

        delete pl.group;

        products.push(pl);
      }

      this.cat.products = products.filter((p) => p.quantity !== 0);

      this.$emit('updateProducts', payload);
      this.sendEvent({
        event: 'Added Recommended Product',
        properties: {
          location: this.$route.name,
          sku: payload.sku,
          type: 'EVT'
        }
      });
    },
    setActiveRecommendations() {
      //get the first 4 off recomendations
      // filter out any items that are already in the box or are not available or have a foodtype of 'dry'

      const recommendations = this.recommendations
        .filter((r) => {
          return (
            !this.cat.products.find((p) => p.sku === r.sku) &&
            r.available &&
            r.foodtype !== 'dry'
          );
        })
        .slice(0, 4);

      // get the rest of the recommendations
      const rest = this.recommendations
        .filter((r) => {
          return (
            !this.cat.products.find((p) => p.sku === r.sku) &&
            r.available &&
            r.foodtype !== 'dry'
          );
        })
        .slice(4);
      // for each of the rest of the recommendations, get their corresponsing product and merge the objects
      this.nextRecommendations = rest.map((r) => {
        const product = this.products.find((p) => p.sku === r.sku);
        return { ...r, ...product };
      });

      // for each of the recommendations, get their corresponsing product and merge the objects
      this.activeRecommendations = recommendations.map((r) => {
        const product = this.products.find((p) => p.sku === r.sku);
        return { ...r, ...product };
      });
    }
  },
  mounted() {
    this.setActiveRecommendations();
    EventBus.$on('recommendation-change', (val) => {
      // get the 1st value from the nextRecommendations array
      const next = this.nextRecommendations.shift();
      if (next) {
        // find the product in activeRecommendations based on the val.sku and replace it with the next recommendation, then remove the first value from nextRecommendations

        const index = this.activeRecommendations.findIndex(
          (r) => r.sku === val.sku
        );
        this.activeRecommendations.splice(index, 1, next);
      } else {
        // remove the product from activeRecommendations based on the val.sku
        const index = this.activeRecommendations.findIndex(
          (r) => r.sku === val.sku
        );
        this.activeRecommendations.splice(index, 1);
      }
      this.sendEvent({
        event: 'Hidden Recommended Product',
        properties: {
          location: this.$route.name,
          sku: val.sku,
          type: 'EVT'
        }
      });
    });
  }
};
</script>
